import Button from "@/components/button";

export default {
    name: "GenericModal",
    props: {
        isHeader: {
            type: Boolean,
            default: true,
        },
        isFooter: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        "neo-button": Button,
    },
    data() {
        return {
            showModal: false,
        };
    },
};
