<template>

        <div class="lgtr-header-value-parent">
			<HeaderGraph/>
			<span class="lgtr-header-value">{{ value }}</span>
		</div>
        
</template>

<script>
import HeaderGraph from "@shared/assets/lgtr-header-graph.svg"


export default {
  components:{
    HeaderGraph,
  }, 
  props: {
    value: {
      type: String,
      default: "",
    },
  },
};
</script>

<style>
.m-width{
  min-width: fit-content
}

.lgtr-header-value{
  position: absolute;
  left: 19px;
  bottom: -4px;
}
.lgtr-header-value-parent{
  position: relative;
  display: flex;
  width: 56px;
  height: 28px;
  align-items: flex-start;
  gap: 10px;

}
</style>