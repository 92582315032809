import Button from "@shared/components/button";
import GenericModal from "@shared/components/genericModal";

export default {
    name: "supportCenter",
    components: {
        "neo-button": Button,
        // "neo-input": Input,
        "neo-generic-modal": GenericModal,
        // nLoader,
    },
    props: [],
    data() {
        return {
            isHeader: true,
            loading: true,
        };
    },
    async created() {},
    computed: {
       
    },
    async mounted() {},
    methods: {
        close() {
            this.$emit("close");
        },
        loadChat() {
            if (document.getElementById("zsiq_float")) document.getElementById("zsiq_float").click();
        },
    },
};
