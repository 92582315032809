<template>
 
        <!-- <DropDownIcon @click="handleDropDownIcon" class="cursor-pointer"/> -->
        
        <div class=" h-8 flex-col w-auto justify-startf w-60 items-end dropdown_parent">
                        <button class="px-2 py-1  rounded flex justify-between 
                      items-center  text-slate-900 text-opacity-75 text-sm " @click="toggleMenu">
        <CreditCard  />
        <div class="flex-col px-4">

          <p  class="text-base-content-300 mb-0  text-sm">{{primaryCreditPack || title}}</p>
          <div class="h-1/2 w-full bg-cst-neutral ">
            <div class="h-1 bg-cst rounded-md" :style="{width: 50+'%'}"> 
              </div>
          </div>
        </div>
                            <font-awesome-icon class="ml-auto mr-2 transition-transform transform ease-out duration-300"
                                :class="showMenu ? ' rotate-180' : 'rotate-0'" :icon="['fas', 'chevron-down']" color="black"
                                size="xs" />
                        </button>

                        <div v-if="this.showMenu && this.showAll" class="w-auto bg-white rounded-md shadow py-2 px-1">
                          <div  v-for="item in listData" :key="item.id"
                              class="dropdown_content text-sm font-small  h-auto p-2 flex flex-items">
                              <CreditCard class="mx-2" />
                              <div class="flex justify-between  items-center" @click="handlePrimary(item.id)">
                                <div class="flex-col mx-2">
                                  <div>
                                    CP-{{item.name}}
                                  </div>
                                  <div class="text-sublabel ">{{ item.description }}</div>
                                </div>
                                <div class="flex-col mx-2">
                                <div class="mx-2">
                                  {{item.remaining_credits*100/item.total_credits}}%
                                </div>
                                <div>
                                  <div class="h-1/2 w-full bg-cst-neutral ">
                                      <div class="h-1 bg-cst rounded-md" :style="{width: item.remaining_credits*100/item.total_credits+'%'}">                               </div>
                                  </div>
                                </div>
                                </div>
                              </div>
                        <span class="z-0  w-7 h-7 border-2 border-white rounded-full bg-red-200 flex justify-center items-center">

                              <UserIcon/>
                              </span>
                          </div>

                          <div v-if="this.showMenu && !this.showAll" class="w-auto bg-white rounded-md shadow py-2 px-1">
                          <div  v-for="item in listData" :key="item.id"
                              class="dropdown_content text-sm font-small  h-auto p-2 flex flex-items">
                              <CreditCard class="mx-2" />
                              <div class="flex justify-between  items-center" @click="handlePrimary(item.id)">
                                <div class="flex-col mx-2">
                                  <div>
                                    CP-{{item.name}}
                                  </div>
                                  <div class="text-sublabel ">{{ item.description }}</div>
                                </div>
                                <div class="flex-col mx-2">
                                <div class="mx-2">
                                  {{item.remaining_credits*100/item.total_credits}}%
                                </div>
                                <div>
                                  <div class="h-1/2 w-full bg-cst-neutral ">
                                      <div class="h-1 bg-cst rounded-md" :style="{width: item.remaining_credits*100/item.total_credits+'%'}">                               </div>
                                  </div>
                                </div>
                                </div>
                              </div>
                        <span class="z-0  w-7 h-7 border-2 border-white rounded-full bg-red-200 flex justify-center items-center">

                              <UserIcon/>
                              </span>
                          </div>
                         <div  
                              class="dropdown_content text-sm font-small cst-label  h-auto p-2 flex flex-items" v-if="listData.length>4" @click="handleDetails">
                            View all Details</div>
                          </div>
                    </div>
    </div> 
</template>

<script>
import CreditCard from "@shared/assets/credit-card-bold.svg"
// import DropDownIcon from "@shared/assets/dropdown-icon.svg"
import UserIcon from "../../assets/svg/user.svg";
// import UserIcon from "../../assets/svg/user.svg";
import axios from "@/axios"


// import BorderBottom from "@shared/assets/border-botton.svg"

export default {
  data(){
    return {
      showMenu:false,
      options:[{credit:5000,percent:10,subLabel:9065498765},
      {credit:1000,percent:10,subLabel:2765498765},
      {credit:8000,percent:70,subLabel:8765490965},
      {credit:9000,percent:80,subLabel:9965498365}],
      showAll:false,
    }
  },
  components:{
    CreditCard,
    UserIcon
  }, 
  methods:{
    handleDropDownIcon(){
      console.log('CLICKED')
    },
    toggleMenu() { this.showMenu = !this.showMenu },
    async handlePrimary(id){
      const url = "api/v1/source-consume-callback/get_user_credit_packs";
      const payload = {"credit_pack_id":id}
      await axios.get(url,payload)

    },
    handleDetails(){
      this.showAll = true
    }

  },
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style>
.m-width{
  min-width:  fit-content
}
.text-sublabel{
  font-size: 10px;
}
.dd-wrapper{
background: white;
padding: 10px;
}
.bg-cst{
  background: #CE1B22;
}
.bg-cst-neutral{
  background: #E8E8E8;
}
.flex-items{
  align-items: center
  ;
}
.cst-label{
  color: #CE1B22;

}
</style>