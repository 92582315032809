<template>
  <div class="flex">
    <div class="flex flex-col  border-solid border-gray-200 container-m-width">
      <div class="flex justify-between items-baseline">
        <p class="text-base-content-300 mb-0  text-sm">{{ title }}</p>
        <ArrowDownSLine @click="handleEdit" class="cursor-pointer" />
      </div>
      <div class="flex  items-baseline">
        <EllipseIcon />
        <p class="font-semibold mb-0 text-sm capitalize value-text" :class="!value && 'text-gray-400 value-text'">
          {{ value }}</p>
      </div>

    </div>
    <div id="progress">
      <svg height="7rem">
        <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="100%">
          <stop offset="0%" stop-color="#56c4fb" />
          <stop offset="100%" stop-color="#0baeff" />
        </linearGradient>
        <path class="grey" d="M5,55 A50, 50 0 0 ,1 105,55" fill='none' />
        <path id="blue" fill='none'  class="blue" d="M5,55 A50 ,50 0 0 ,1 105 ,55" :style="handleColor"/>
          <text x="18%" y="40%" dominant-baseline="middle" text-anchor="middle" style="font-size:18px;">{{this.progress}}</text>
  </svg>
</div>
        </div>
        <!--<div style="min-width:70px;backgroundColor:lightGrey;clip-path:ellipse(198px 34px at 50% 0%)" class="rounded-full">
        <div style="max-width:70px;background-image: conic-gradient(lightGrey 0deg, lightGrey 90deg, white 90deg, white 198deg, white 198deg,#CE1B22 270deg, #CE1B22 320deg,lightGrey 320deg, lightGrey 360deg)" class="rounded-full progress_fill">
        <div style="max-width:198px;min-height:57px;backgroundColor:white;clip-path:ellipse(198px 34px at 50% 0%) " class="rounded-full m-1 text-center">
        <span class="text-red-600">30</span>
        </div>
      </div>
        </div>-->
</template>

<script>
import ArrowDownSLine from "@shared/assets/arrow-down-s-line.svg"
import EllipseIcon from "@shared/assets/ellipse.svg"



export default {
  data() {
    return {
      progress:50,
      redColor: "red",
      greenColor: "green"
    }
  },
  components: {
    ArrowDownSLine,
    EllipseIcon
  },
  methods: {
    handleEdit() {
      console.log('CLICKED')
    }
  },
  computed:{
    handleColor(){
      if(this.progress<50)
      return {
        strokeDashoffset:180-this.progress*180/100,
        stroke: this.redColor

      }
      else 
      return {
        strokeDashoffset:180-this.progress*180/100,
        stroke: this.greenColor
      }
    }
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    // creditScore: {
    //   type: String,
    //   default:"",
    // },
    creditScore: {
      type: String,
      default:"",
    }
  },
};
</script>

<style scoped>
.value-text {
  color: #631AFF !important;
}

.container-m-width {
  min-width: 198px
}

#progress {
  height: 56px;
  width: 40px
}

svg {
  display: block;
}

path {
  stroke-linecap: round;
  stroke-width: 4;
}

path.grey {
  stroke: #e7e7e8;
}

path.blue {
  stroke: url(#gradient);
  stroke-dasharray: 170;
  stroke-dashoffset: 198;
  animation: dash 3s ease-out forwards;
}
</style>